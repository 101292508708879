<template>
    <section class="s-our-news">
		<div class="container">
			<h2 class="title">Temas</h2>
			<div class="news-cover row">
				<div class="col-12 col-md-6 col-lg-4" v-for="(item, index) in noticedetail">
					<div class="news-item">
						<h6 class="title text-center"><router-link :to="`/blog/${item.url}`">{{ item.title }}</router-link></h6>
						<div class="news-post-thumbnail">
							<router-link :to="`/blog/${item.url}`"><img class="lazy" loading="lazy" :src="item.image" data-src="assets/img/news-1.jpg" alt="news"></router-link>
						</div>
						<div class="meta">
							<span class="date"><i class="fa fa-calendar" aria-hidden="true"></i> {{ item.date }}</span>
							<span class="post-by"><i class="fa fa-user" aria-hidden="true"></i> By <a>{{ item.by_of }}</a></span>
						</div>
						<div class="post-content">
							<router-link :to="`/blog/${item.url}`"><p>{{ item.resume }}</p></router-link>
						</div>
						<router-link :to="`/blog/${item.url}`" class="btn-news">leer más</router-link>
					</div>
				</div>
			</div>
			<div class="btn-cover"><router-link :to="`/blog`" class="btn"><span>Ver más</span></router-link></div>
		</div>
	</section>
</template>

<script>
import { sliderService } from "@/services/sliderService.js";
import { ApiService } from "@/services/ApiService.js";
import { ref } from 'vue';
export default {
    props:{},
    components:{},
    data(){
        return{
            noticedetail : ref([]),
        };
    },
    created(){
        //sliderService.getBlogs().then((data) => (this.noticedetail = data));
        //ApiService.get("/topsale", true).then((data) => (this.imgSale = data));//Ya está el llamado a la API
		ApiService.get('/blogsCar')//Llamamos al Service de las apis para traer datos en get
			.then(async data => {
				if ('detail' in data){
					this.$router.push('/mantenimiento')
				}else{
					
					//data = await ImageService.updateUrl(data); //Se llama el Service para actualizar el utl de la imagen 
					
					this.noticedetail = data;
				}
				
			})
			.catch(error => {
				console.error('Error en la solicitud:', error.message);
			});
    },
    methods:{},
}
</script>

<style scoped>

</style>