<template>
    <div style="" class="div-slider">
        <swiper :spaceBetween="10" :loop="true"
        :breakpoints="{
            '640': {
                slidesPerView: 1,
                spaceBetween: 10,
            },
            '768': {
                slidesPerView: 1,
                spaceBetween: 10,
            },
            '1024': {
                slidesPerView: 1,
                spaceBetween: 10,
            },
        }"
        :centeredSlides="true" :autoplay="{ delay: 3500, disableOnInteraction: false, }" :pagination="{ clickable: true, }" :navigation="true" :modules="modules" class="mySwiper">
            <swiper-slide v-for="(item, index) in slidersImg">
                <img loading="lazy" :src="item.image" :alt="item.title" style="width: 100%;">
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';

import 'swiper/css/pagination';
import 'swiper/css/navigation';


import { sliderService } from '@/services/sliderService';
import { ApiService } from '@/services/ApiService.js';
import {ref, onMounted, onBeforeUnmount} from 'vue';
    export default {
        components:{
            Swiper,
            SwiperSlide,
        },
        props:{

        },
        setup(){
            onMounted(() => {
                sliderService.getImagesSlider().then((data) => (slidersImg.value = data));
				//ApiService.get("/slider", true).then((data) => (slidersImg.value = data));//Ya está el llamado a la API
            });
            const slidersImg = ref();

            const mostrar = () => {
                console.log(slidersImg.value);
            }
            return{slidersImg, mostrar, modules: [Autoplay, Pagination, Navigation],};
        },
    }
</script>

<style scoped>
.swiper {
    width: 100%;
}

</style>