<template>
    <BannerBlue title="Carrito"/>
    <div class="container">
        <div class="row mt-3 mb-3" v-show="cantProd > 0">
            <div class="col-sm-8 mb-2">
                <div>
                    <Card>
                        <template #content>
                            <DataView :value="productCart">
                                <template #list="slotProps">
                                    <div class="container-list mt-2">
                                        <div class="fixed-width">
                                            <router-link :to="`/producto/${slotProps.data.brand}/${slotProps.data.model}`" style="width:100%;" @click="top">
                                                <img loading="lazy" class="w-9 border-round" :src="slotProps.data.image" :alt="slotProps.data.name" onerror="this.src='https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/productos/noencontrado/noencontrado.jpg'"/>
                                            </router-link>
                                        </div>
                                        <div class="flexible">
                                            <router-link :to="`/producto/${slotProps.data.brand}/${slotProps.data.model}`" style="width:100%;" @click="top">
                                                <div class="text-2xl font-bold text-900">{{ slotProps.data.title }}</div>
                                            </router-link>
                                            <div class="text-700" style="font-size: 18px;">
                                                {{ slotProps.data.brand }} {{ slotProps.data.model }}
                                            </div>
                                            <div class="single-quanity infoProd">
                                                <div class="cantdiv">
                                                    <strong>Cantidad:</strong>
                                                    <button class="btn-plow" style="margin-left: 10px;" @click="lessprod(slotProps.index)" :disabled="isDisabled[slotProps.index]">
                                                        <svg fill="#000000" width="30px" height="25px" viewBox="-6.5 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>hyphen</title> <path d="M13.875 12.906v2.281h-8.563v-2.281h8.563z"></path> </g></svg>
                                                    </button>
                                                    <input type="number" v-model="slotProps.data.quantity" @input="validateInput" :max="10" min="1" class="inpCantProd" />
                                                    <button class="btn-plow" @click="plusprod(slotProps.index)">
                                                        <svg width="20px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 12H20M12 4V20" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="fixed-width">
                                            <div class="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2">
                                                <span v-if="false" class="text-2xl font-semibold">${{ (slotProps.data.cost).toLocaleString('es-MX',{minimumFractionDigits: 2,maximumFractionDigits: 2,}) }}</span>
                                                <i class="pi pi-trash icon-delete" @click="deleteProduct(slotProps.index)"></i>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </DataView>
                        </template>
                    </Card>
                </div>
            </div>
            <div class="col-sm-4" style="padding-right: 20px;">
                <Card>
                    <template #content>
                        <div class="container-total">
                            <div style="display: flex; justify-content: center;" class="mt-2 mb-2">
                                <h4 style="font-family: Verdana;" v-if="false">Total a pagar</h4>
                                <h4 style="font-family: Verdana;" v-else>Cotizar</h4>
                            </div>
                            <Divider type="solid"/>
                            <div class="mt-4 mb-4">
                                <table style="width: 98%;" v-if="false">
                                    <tr>
                                        <td><strong>Subtotal:</strong></td>
                                        <td class="cost-rigth">${{ (subtotalCart).toLocaleString('es-MX',{minimumFractionDigits: 2,maximumFractionDigits: 2,}) }} MXN </td>
                                    </tr>
                                    <tr>
                                        <td><strong>IVA:</strong></td>
                                        <td class="cost-rigth">${{ (subtotalCart * 0.16).toLocaleString('es-MX',{minimumFractionDigits: 2,maximumFractionDigits: 2,}) }} MXN</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Total:</strong></td>
                                        <td class="cost-rigth">${{ (subtotalCart * 1.16).toLocaleString('es-MX',{minimumFractionDigits: 2,maximumFractionDigits: 2,}) }} MXN</td>
                                    </tr>
                                </table>
                            </div>
                            <Divider type="solid"/>
                            <div class="row">
                                <div class="col"  style="display: flex; justify-content: end;">
                                    <button class="btnPay" @click="emailAlert()"> 
                                        Enviar Cotización
                                        <i class="pi pi-credit-card" style="color: #000;"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </template>
                </Card>
            </div>
        </div>
        <div class="row" v-show="cantProd <= 0">
            <div class="col">
                <h2>No se encuentran productos en el carrito</h2>
                <img src="../assets/img/pedro_triste.png" alt="No encontrado" style="width: 200px;" loading="lazy">
                <br>
                <button
                    class="btnGen mt-2"
                    @click="$router.push('/tienda')"
                >
                Ver productos
                </button>
            </div>
        </div>
    </div>
    <div class="card flex justify-content-center">
        <Dialog v-model:visible="visible" modal header="Edit Profile" :style="{ width: '25rem' }">
            <span class="p-text-secondary block mb-5">Update your information.</span>
            <div class="flex align-items-center gap-3 mb-3">
                <label for="username" class="font-semibold w-6rem">Username</label>
                <InputText id="username" class="flex-auto" autocomplete="off" />
            </div>
            <div class="flex align-items-center gap-3 mb-5">
                <label for="email" class="font-semibold w-6rem">Email</label>
                <InputText id="email" class="flex-auto" autocomplete="off" />
            </div>
            <div class="flex justify-content-end gap-2">
                <Button type="button" label="Cancel" severity="secondary" @click="visible = false"></Button>
                <Button type="button" label="Save" @click="sale()"></Button>
            </div>
        </Dialog>
    </div>
    
</template>

<script>
import BannerBlue       from '@/components/BannerBlue.vue';
import { ref }          from 'vue';
import { ApiService }   from '@/services/ApiService';
import MercadoPago      from 'mercadopago';
import Swal from 'sweetalert2'
export default {
    props:{},
    components:{
        BannerBlue,
    },
    data(){
        return{
            productCart: ref(),
            cantProd: 0,
            subtotalCart : 0,
            isDisabled: [],
            visible: false,
            email:'',
        };
    },
    mounted: function(){
        this.getData();
    },
    methods:{
        makePayment() {
            // Crea un objeto con los datos del pago
            const payment = {
                amount: 100,
                currency: 'ARS',
                payer: {
                    name: 'Juan Pérez',
                    email: 'juan.perez@example.com',
                },
            };

            // Crea un botón de pago utilizando el SDK de Mercado Pago
            const button = MercadoPago.createPaymentButton(payment);

            // Agrega el botón al DOM
            document.body.appendChild(button);
        },
        async emailAlert(){
            Swal.fire({
                title: "Ingrese su correo electronico",
                input: "text",
                inputAttributes: {
                    autocapitalize: "off"
                },
                showCancelButton: true,
                confirmButtonColor: "#EEC815",
                confirmButtonText: "Enviar",
                showLoaderOnConfirm: true,
                preConfirm: async (login) => {
                    
                    try {
                        if(this.validateEmail(login)){
                            console.log(this.productCart);
                            /*
                            var response = await ApiService.post('/sendCoti', data)//Llamamos al Service de las apis para traer datos en get
                                .then(async data => {
                                    return data;
                                })
                                .catch(error => {
                                    console.error('Error en la solicitud:', error.message);
                                });
                            */
                            var today = new Date();
                            var jProductCart = {
                                "email": login,
                                "jproductCart" : JSON.stringify(this.productCart),
                                "id_user": "0",
                            }
                            console.log(jProductCart);
                            var response = await ApiService.post('/sendCoti', jProductCart)//Llamamos al Service de las apis para traer datos en get
                                .then(async data => {
                                    console.log(data);
                                    return data;
                                })
                                .catch(error => {
                                    console.error('Error en la solicitud:', error.message);
                                });
                            return Swal.showValidationMessage(`
                            Email valido
                            `);
                        }else{
                            return Swal.showValidationMessage(`
                            Email invalido
                            `);
                        }
                        return response.json();
                        } catch (error) {
                        Swal.showValidationMessage(`
                            Request failed: ${error}
                        `);
                    }
                },
                customClass: {
                    input: 'custom-input',
                    popup: 'custom-popup'
                },
                allowOutsideClick: () => !Swal.isLoading()
                }).then((result) => {
                if (result.isConfirmed) {
                    console.log(result);
                    Swal.fire({
                    title: `${result.value.login}'s avatar`,
                    imageUrl: result.value.avatar_url
                    });
                }
            });
        },
        validateEmail(email) {
            // Expresión regular más estricta para validar un email electrónico
            const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            return regex.test(email);
        },
        async sale(){
            var data ={
                'email':'a',
                'product': this.productCart
            }
            await ApiService.post('/sendCoti', data)//Llamamos al Service de las apis para traer datos en get
                .then(async data => {
                    console.log(data);
                    if ('detail' in data){
                        this.$router.push('/mantenimiento')
                    }else{
                        console.log(data);

                    }
                })
                .catch(error => {
                    console.error('Error en la solicitud:', error.message);
                });
            /*
            const mp = new MercadoPago('APP_USR-a68157fb-5513-4dc7-adbf-709ba3e46766', {
                locale: 'es-MX'
            });
            mp.bricks().create("wallet", "wallet_container", {
                initialization: {
                    preferenceId: "<PREFERENCE_ID>",
                },
            });
            */
        },
        deleteProduct(index) {
            this.$store.commit('deleteProduct', index);
            this.getData();
            //localStorage.setItem('jproducts',JSON.stringify(this.$store.getters.getProducts));
        },
        getData(){
            this.productCart = this.$store.getters.getProducts;
            this.cantProd = (this.$store.getters.getProducts).length;
            this.subtotalCart = 0;
            for(var i = 0; i < this.cantProd; i++){
                this.subtotalCart = this.subtotalCart + (this.productCart[i].quantity * this.productCart[i].cost);
                if(this.productCart[i].quantity > 1){this.isDisabled[i] = false;}else{this.isDisabled[i] = true;}
            }
        },
        plusprod(index){
            console.log(this.productCart[index].quantity);
            if(this.productCart[index].quantity > 1) {this.isDisabled[index] = false;}
            var jProductChange = {
                model: this.productCart[index].model,
                brand: this.productCart[index].brand,
                quantity: 1,
            }
            this.$store.dispatch('findAndUpdateProduct', jProductChange);
            this.getData();
        },
        lessprod(index){
            //if(this.productCart[index].quantity > 1){
                var jProductChange = {
                    model: this.productCart[index].model,
                    brand: this.productCart[index].brand,
                    quantity: -1,
                }
                this.$store.dispatch('findAndUpdateProduct', jProductChange);
            /*}else{
                this.isDisabled = true;
            }*/
            this.getData();
        }
    },
}
</script>

<style scoped>
.custom-input {
    max-width: 90px; /* Or set a specific width in pixels */
}

.swal2-validation-message {
  font-size: 14px; /* Ajustar el tamaño del mensaje de validación */
  color: #f27474; /* Cambiar el color del mensaje de validación si es necesario */
}

.btnPay {
 padding: 0.8em 1.8em;
 border: 2px solid #000000;
 position: relative;
 overflow: hidden;
 background-color: transparent;
 text-align: center;
 text-transform: uppercase;
 font-size: 16px;
 transition: .3s;
 z-index: 1;
 font-family: inherit;
 color: #000;
}

.btnPay::before {
 content: '';
 width: 0;
 height: 300%;
 position: absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%) rotate(45deg);
 background: #FECA31;
 transition: .5s ease;
 display: block;
 z-index: -1;
}

.btnPay:hover::before {
 width: 105%;
}

.btnPay:hover {
 color: #111;
}


.cantdiv{
	display: flex;
	align-items: center;
	
}
.btn-plow {
    background: none;
	background: #EEC815;
    border: 2px solid #EEC815;
    padding: 2px;
	height: 2rem;
    cursor: pointer;
    width: 40px;
    border-radius: 2px;
    transition: background-color 0.5s ease;
}

.btn-plow:hover {
    background: rgba(247, 220, 88, 0.664);
}
.btnCant{
	font-size: 2rem;
	width: 1rem;
	height: 2.5rem;
	border-radius: 2px;
	margin: 0 10px;
	background: #EEC815;
}
.inpCantProd{
	width:3.5rem;
	height: 2rem;
	font-size: 1.4rem;
	text-align: center;
	border: 2px solid #EEC815;
}
.infoProd{
	text-align:start;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
	-webkit-appearance: none; 
	margin: 0; 
}

input[type=number] { 
    /*-moz-appearance:textfield; */
    -webkit-appearance: textfield; /* Safari */
    -moz-appearance: textfield; /* Firefox */
    appearance: textfield; /* Estándar */
}
.cost-rigth{
    text-align: end;
}
.icon-delete:hover{
    cursor: pointer;
    color: #000;
}
.container-total{
    padding: 10px;
}
.container-list {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0 0 10px 10px;
    border-bottom: 3px solid rgba(224, 224, 224, 0.418);
    flex-direction: row; /* Establecer la dirección predeterminada a fila */
}
@media (max-width: 960px) {
    .container-list {
        flex-direction: column; /* Cambiar a dirección de columna cuando el ancho sea menor a 1000px */
    }
}
.fixed-width {
    width: 200px;
    text-align: center;
    padding: 10px;
}
.flexible {
    flex: 1;
    text-align: start;
    padding: 10px;
}
.container-list:hover {
    box-shadow: 10px;
    background: rgba(224, 224, 224, 0.418);
    border-bottom: 3px solid rgba(224, 224, 224, 0);
}
</style>