<template>
    
    <div class="container mt-5 mb-6" >
        <h2>categorías</h2>
        <div class="container2">
            
            <swiper :slidesPerView="1" :spaceBetween="10" :navigation="true" :loop="true" :pagination="{ clickable: true, dynamicBullets: true,}"
                :breakpoints="{ '640': { slidesPerView: 1, spaceBetween: 20, }, '992': { slidesPerView: 2, spaceBetween: 40, }, '1366': { slidesPerView: 3, spaceBetween: 15, }, }" :modules="modules" class="mySwiper"
            >
                <swiper-slide v-for="(item, index) in dataCat" style="">
                    <div class="custom-div">
                        <img loading="lazy" :src="item.image" :alt="item.title" style="flex: 0;">
                        <h3>{{ item.title }}</h3>
                        <router-link :to="`/tienda${item.url}`" class="btn"><span>ver más</span></router-link>
                    </div>
                </swiper-slide>
            </swiper>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import Carousel from 'primevue/carousel';
import { sliderService } from "@/services/sliderService.js";
import { ApiService } from "@/services/ApiService.js";
import { ref, onMounted } from 'vue';
export default {
    props:{},
    components:{
        Carousel,
        Swiper,
        SwiperSlide,
    },
    data(){
        return{
            modules: [Pagination, Navigation],
            responsiveOptions : ref([
                {
                    breakpoint: '1400px',
                    numVisible: 2,
                    numScroll: 2,
                },
                {
                    breakpoint: '1199px',
                    numVisible: 3,
                    numScroll: 2,
                },
                {
                    breakpoint: '767px',
                    numVisible: 2,
                    numScroll: 2,
                },
                {
                    breakpoint: '575px',
                    numVisible: 1,
                    numScroll: 1,
                },
            ]),
            dataCat: ref([]),
        };
    },
    methods:{},
    created(){
        sliderService.getAllDetailCategory().then((data) => (this.dataCat = data));
        //ApiService.get("/categoriasCar", true).then((data) => (this.imgSale = data));//Ya está el llamado a la API
    },
}
</script>

<style scoped>
.container2 {
    display: flex;
}

.custom-div {
    padding: 5px;
    background: #fff;
    
    box-shadow: 0px 0px 5px rgb(115, 115, 115, 0.5) inset;
    position: relative;
    background-size: contain;
}

h3 {
    position: absolute;
    top: 30%;
    left: 30%;
    transform: translate(-50%, -50%);
}

.btn {
    position: absolute;
    bottom: 60px;
    left: 30%;
    transform: translateX(-50%);
}

.swiper {
    align-items: center;
    justify-content: center;
}
</style>