<template>
    <BannerBlue title="Catálogos"/>
    <div class="container">
        <div class="contenedor-externo">
            <div v-for="(imagen, index) in catalogos" :key="index" class="contenedor-interno">
                <div class="title-cat mb-1">
                    <a :href="imagen.pdf" class="img-cat" target="_blank">
                        <h4 style="bottom:5px;">{{ imagen.title }}</h4>
                    </a>
                </div>
                <a :href="imagen.pdf" class="img-cat" target="_blank">
                    <img loading="lazy" :src="imagen.image" :alt="imagen.title" />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import BannerBlue from '@/components/BannerBlue.vue';
import { ref } from 'vue';
export default {
    props:{},
    components:{
        BannerBlue,
    },
    data(){
        return{
            layout: ref('grid'),
            catalogosOrdenados: ref([]),
            catalogos:[
                {
                    title: 'Urrea Plomería',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/2.png',
                    pdf:'https://drive.google.com/file/d/1xTqZ6jrIZWrG-M8AfP_yJ_tPS7oQg3TK/view',
                    imp:3
                },
                {
                    title:'Tecnolite',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/3.png',
                    pdf:'https://drive.google.com/file/d/1PX_rN5kATmoToisIBd-1gDuQQ7Phuxf2/view',
                    imp:10
                },
                {
                    title:'Sika',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/4.png',
                    pdf:'https://drive.google.com/file/d/1fwgG0ppgLVQJUBili3g9oCE1NMdTGg6S/view',
                    imp:10
                },
                {
                    title:'Sika Guia',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/5.png',
                    pdf:'https://drive.google.com/file/d/1skhYFHEeHb-A8RajcM2jg1PwEEjk5Edq/view',
                    imp:10
                },
                {
                    title:'Milwaukee',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/6.png',
                    pdf:'https://drive.google.com/file/d/1YNvfvd6Dc9EYwYlPiEWIGtovZQ5RSvBa/view',
                    imp:10
                },
                {
                    title:'Milwaukee Equpos de Protección',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/6.1.jpeg',
                    pdf:'https://drive.google.com/file/d/158-8Q6ewQWlQ2kJmrCAwOLRxo2jwMHDv/view',
                    imp:10
                },
                {
                    title:'Master Lock',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/7.png',
                    pdf:'https://drive.google.com/file/d/1qCuUv6-VSrTCW142ktH-9jxv7gw3zSAg/view',
                    imp:10
                },
                {
                    title:'Urrea',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/urrea2.jpg',
                    pdf:'https://drive.google.com/file/d/1ENFeyzq-naAEYNaV0xduLP9NQFT0gPyP/view',
                    imp:1
                },
                {
                    title:'Handyhome',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/11.png',
                    pdf:'https://drive.google.com/file/d/1NrYZFbcL5g8HC2j-yKrnvh8IM-Q8iF0g/view',
                    imp:10
                },
                {
                    title:'Dexter',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/12.png',
                    pdf:'https://drive.google.com/file/d/1KLTk7-cp1oDSwrBDglWYtiAef1ATdf5G/view',
                    imp:10
                },
                {
                    title:'Weldforce',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/1.png',
                    pdf:'https://drive.google.com/file/d/1Zh3suZd-E9YbWNIxUuQ_P5YRS9scMc-q/view',
                    imp:10
                },
                {
                    title:'Volt',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/13.png',
                    pdf:'https://drive.google.com/file/d/1gejn6g5US848idUj-geHOeQZnyyUjOo7/view',
                    imp:10
                },
                {
                    title:'Skil',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/14.png',
                    pdf:'https://drive.google.com/file/d/1R-nr0EHQkv6FhK6vq6upJx2OgH0nYTDL/view',
                    imp:10
                },
                {
                    title:'Lincoln electric',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/15.png',
                    pdf:'https://drive.google.com/file/d/1IeaFLQJecaGmqYGlcrRBlQYAZxcmqIc1/view',
                    imp:10
                },
                {
                    title:"Mikel's",
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/16.png',
                    pdf:'https://drive.google.com/file/d/1q4R7taEH7hgCvgel_W_5sl350C57LLde/view',
                    imp:10
                },
                {
                    title:'Dremel',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/17.png',
                    pdf:'https://drive.google.com/file/d/18b4QHGyQ7tAjbd34-cyz-lSD3ThjCHnE/view',
                    imp:10
                },
                {
                    title:'Bosch',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/18.png',
                    pdf:'https://drive.google.com/file/d/1NcPZMm4crOOntPjPfrbH7N52FJIVYPit/view',
                    imp:10
                },
                {
                    title:'Tonka',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/19.png',
                    pdf:'https://drive.google.com/file/d/1SxN-3cj4yb_iqhJ4gJJruS5_6JuNIjV_/view',
                    imp:10
                },
                {
                    title:'AX Tech',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/20.png',
                    pdf:'https://drive.google.com/file/d/1tUjK1IXRA8aRk7nFKwxgLz2cQ6pFdaUx/view',
                    imp:10
                },
                {
                    title:'Bondhus',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/21.png',
                    pdf:'https://drive.google.com/file/d/1N0oFBJu3xLwmDaLdgT6lTG33BWgYCY5c/view',
                    imp:10
                },
                {
                    title:'Anclo',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/22.png',
                    pdf:'https://drive.google.com/file/d/12EJX5qGiPTWHmSO0gOUF1-TsSl3vroip/view',
                    imp:10
                },
                {
                    title:'Safefit',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/23.png',
                    pdf:'https://drive.google.com/file/d/14EHim4uv5Rd-aPw0rHkmoDoPYM-1OELH/view',
                    imp:10
                },
                {
                    title:'Truper',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/24.png',
                    pdf:'https://www.truper.com/CatVigente/',
                    imp:5
                },
                {
                    title:'Jyrsa',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/25.png',
                    pdf:'https://drive.google.com/file/d/1mLikaa_Lb4Pz8E9v1RVDGlX4BAxzTGvl/view',
                    imp:10
                },
                {
                    title:'Makita',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/26.png',
                    pdf:'https://drive.google.com/file/d/1LS6foR_LI0fgFtRJ9zGNoS9tA2zuBDQt/view',
                    imp:10
                },
                {
                    title:'BYP',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/27.png',
                    pdf:'https://drive.google.com/file/d/1piU2IqtKbD17nxqHXkeJ2Lr8cOg0DFrd/view',
                    imp:10
                },
                {
                    title:'Irwin',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/28.jpg',
                    pdf:'https://drive.google.com/file/d/1HCyA3pB6MjdzdRS2x6sz0rLL-4XjkJKC/view',
                    imp:10
                },
                {
                    title:'DeWalt Inalámbricas',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/29.jpg',
                    pdf:'https://drive.google.com/file/d/1DnReWMFw6gIlK5QMiUlHHUMPCg4EaLea/view',
                    imp:10
                },
                {
                    title:'DeWalt Herramientas Eléctricas',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/30.jpg',
                    pdf:'https://drive.google.com/file/d/1lvmS_eTd_A57BGbPMIL-j67_6pCqlksR/view',
                    imp:10
                },
                {
                    title:'Black&Decker',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/31.jpg',
                    pdf:'https://drive.google.com/file/d/1EUhOl9K7m0fOSW3itkib6XmzxwVRihzt/view',
                    imp:10
                },
                {
                    title:'Stanley',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/32.jpg',
                    pdf:'https://drive.google.com/file/d/1uG19kxXzpLv0Qk6911hrEPtyLYl2St85/view',
                    imp:10
                },
                {
                    title:'Austromex',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/33.jpg',
                    pdf:'https://drive.google.com/file/d/1f_JALtF1iL6LMBC8QBytaoI_fJyWTPCK/view',
                    imp:10
                },
                {
                    title:'Klein Tools',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/34.jpg',
                    pdf:'https://drive.google.com/file/d/1Pg8u7NCiMc4pq6e79Ipfk-l8bqiiCN9l/view',
                    imp:10
                },
                {
                    title:'Klein tools Nuevos Productos',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/35.jpg',
                    pdf:'https://drive.google.com/file/d/1tt5JXBUqnse_p3yATk2IHRNk2sy2Di9S/view',
                    imp:10
                },
                {
                    title:'Klein Tools Seguridad Industrial',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/36.jpg',
                    pdf:'https://drive.google.com/file/d/11Q5fsogA7rum-mJwmhEDPlJm6GNEvPKW/view',
                    imp:10
                },
                {
                    title:'Klein Tools Prueba y Medición',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/37.jpg',
                    pdf:'https://drive.google.com/file/d/1wIxhkgrEz4FINDJ9eFAfCl3CD_hRoBUh/view',
                    imp:10
                },
                {
                    title:'Klein Tools Servicio Eléctrico',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/38.jpg',
                    pdf:'https://drive.google.com/file/d/1POxcTbokoLVJUv2XtkVO6pC5j7qTwaX8/view',
                    imp:10
                },
                {
                    title:'Klein Tools Tensores',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/39.jpg',
                    pdf:'https://drive.google.com/file/d/1Q1zo40UKsNxnfH4OY8U2ikk03K4cBos0/view',
                    imp:10
                },
                {
                    title:'Klein Tools Industria Minera',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/40.jpg',
                    pdf:'https://drive.google.com/file/d/1vSWrlwoDUoFkmXav_LnPG5kB6zwM-9kN/view',
                    imp:10
                },
                {
                    title:'Klein Tools Acero & Concreto',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/41.jpg',
                    pdf:'https://drive.google.com/file/d/1MyBuMNcFb85WMUStNZ9coXPSM30ZeMok/view',
                    imp:10
                },
                {
                    title:'Klein Tools Climatización ',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/42.jpg',
                    pdf:'https://drive.google.com/file/d/1ENe6B12xBCdXQvWjfvD7m17D-mLxoDGL/view',
                    imp:10
                },
                {
                    title:'Klein Tools Telecomunicaciones',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/43.jpg',
                    pdf:'https://drive.google.com/file/d/1BQfzcfelmogU_Ku7_RCF6XY3qVUdyG3L/view',
                    imp:10
                },
                {
                    title:'Klein Tools Contratista',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/44.jpg',
                    pdf:'https://drive.google.com/file/d/1pn0n2ZfQieABuSV8uZpRGsfJpwGYfyJ8/view',
                    imp:10
                },
                {
                    title:'Tulmex Klein Tools',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/45.jpg',
                    pdf:'https://drive.google.com/file/d/1V5ZQwt_7XFFCUotkmuvtNAP1EH19P2_2/view',
                    imp:10
                },
                {
                    title:'Cleveland',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/46.jpg',
                    pdf:'https://drive.google.com/file/d/11kpmlGd8MNztmkIJl6jgv_1wsrT-rOa6/view',
                    imp:10
                },
                {
                    title:'SYNERGY',
                    image:'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Catalogos/47.jpg',
                    pdf:'https://drive.google.com/file/d/1puEyH_Bu0xQiin3XQu64gxssjnTxL9iW/view',
                    imp:10
                },

            ]
        };
    },
    mounted: function(){
        this.ordenarCatalogos();
    },
    methods:{
        ordenarCatalogos() {
        // Ordenar primero por "imp" y luego por "title"
        this.catalogos.sort((a, b) => {
            if (a.imp !== b.imp) {
                return a.imp - b.imp;
            } else {
                return a.title.localeCompare(b.title);
            }
        });
        },
    },
}
</script>

<style scoped>
h4{
    line-height: 40px;
}
h4:hover{
    color: #ffbf00;
}
img:hover{
    box-shadow: 0 0 20px #ffbf00;
}
.contenedor-externo {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.contenedor-interno {
    margin-right: 10px; /* Espacio entre los contenedores internos, ajusta según tu preferencia */
    max-width: 400px;
}
.title-cat{
    height: 80px;
    display: flex;
    text-align: start;
    align-items: flex-end;
}
.title-cat a h3{
    color: #000;
}
.title-cat img{
    box-shadow: none;
}
.contenedor-interno a img:hover ~ .title-cat a h3{
    color: #ffbf00;
}
.title-cat a h3:hover{
    color: #ffbf00;
}
img {
    width: 100%; /* Asegura que las imágenes ocupen todo el ancho del contenedor interno */
    height: auto; /* Permite que la altura se ajuste proporcionalmente al ancho */
    border-radius: 10px;
}
@media (max-width: 1200px) {
    .img{
        width: 80%;
    }
    .title-cat{
        padding-left: 15px;
    }
}
</style>