<template>
    <section class="s-feedback" style="">
        <span class="effwct-bg-feedback" style=""></span>
		<span class="mask"></span>
        <div class="container">
            <h2 class="title">comentarios</h2>
            <div class="feedback-slider">
                <swiper :slidesPerView="1" :loop="true" :navigation="true" :spaceBetween="10" :pagination="{ clickable: true, }" 
                    :breakpoints="{ 
                        '640': { 
                            slidesPerView: 1, 
                            spaceBetween: 20, 
                        }, 
                        '768': { 
                            slidesPerView: 2, 
                            spaceBetween: 40, 
                        }, 
                        '1200': { 
                            slidesPerView: 3, 
                            spaceBetween: 50, 
                        }, 
                    }" :modules="modules" class="mySwiper"
                >
                    <swiper-slide v-for="(item, index) in imgSale">
                        <div class="feedback-slide" >
                            <div class="feedback-item" style="max-height: 300px; ">
                                <div class="feedback-content">
                                    <p style="" class="pComment">
                                        “{{ item.description }}”</p>
                                </div>
                                <div class="feedback-item-top">
                                    <img loading="lazy" :src="item.image" alt="photo">
                                    <div class="feedback-title">
                                        <h5 class="title"><span>{{ item.brand }}</span></h5>
                                        <Rating v-model="item.rating" :cancel="false" readonly />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
            <!--
            <Carousel :value="imgSale" :numVisible="3" :numScroll="1" :responsiveOptions="responsiveOptions" circular  :showIndicators="true" :autoplayInterval="5000">
                <template #item="slotProps">
                    <div class="feedback-slide" >
                        <div class="feedback-item" style="height: 300px; ">
                            <div class="feedback-content">
                                <p style="" class="pComment">
                                    “{{ slotProps.data.description }}”</p>
                            </div>
                            <div class="feedback-item-top">
                                <img :src="slotProps.data.image" alt="photo">
                                <div class="feedback-title">
                                    <h5 class="title"><span>{{ slotProps.data.brand }}</span></h5>
                                    <Rating v-model="slotProps.data.rating" :cancel="false" readonly />
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </Carousel>
            -->
            </div>
        </div>
    </section>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, Navigation } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Carousel from 'primevue/carousel';
import { sliderService } from '@/services/sliderService';
import { ApiService } from '@/services/sliderService.js';
import { ref } from 'vue';
    export default {
        name: "Topsale",
        props:{
			numVisible: Number 
		},
        components:{
			Carousel,
            Swiper,
            SwiperSlide,
		},
        data(){
            return{
                modules: [Pagination, Navigation],
				valor: ref(true),
                imgSale : ref(),
				responsiveOptions: ref([
					{
						breakpoint: '1367px',
						numVisible: 2,
						numScroll: 1
					},
					{
						breakpoint: '991px',
						numVisible: 2,
						numScroll: 1
					},
					{
						breakpoint: '767px',
						numVisible: 1,
						numScroll: 1
					}
				]),
            }
        },
        created(){
            sliderService.getImagesSlider().then((data) => (this.imgSale = data));
			//ApiService.get("/topsale", true).then((data) => (this.imgSale = data));//Ya está el llamado a la API
        },
        methods:{
			topSale() {
				window.scrollTo(0, 0);
                this.$emit("topSale", this.valor); // Emitir un evento hacia el componente padre para mandar arriba y cerrar modal
                //sintaxis, ("nombre-de-funcion", variable-a-enviar)
            },
        }
    }
</script>

<style scoped>
.pComment{
    height: 105px; 
    max-height: 106px; 
    text-overflow: ellipsis; 
    overflow: hidden;
    text-transform: lowercase;
}

.pComment::first-letter {
  text-transform: uppercase; /* Convierte la primera letra después del punto en mayúscula */
}
</style>